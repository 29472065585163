@import '../../theme.scss';

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  &__icon {
    display: block;
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 100%;
    margin-bottom: .5rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    text-align: center;
    padding-left: 1rem;
    color: #fff;

    h1 {
      font-weight: 900;
    }

    h2 {
      font-weight: 400;
    }
  }

  &__social {
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    list-style: none;
    color: $color-light;

    a {
      color: inherit;
      padding: .25rem 1rem;
    }

    svg {
      width: 20px;
      height: auto;
    }
  }
}