@import '../../theme.scss';

.button {
  display: flex;
  align-items: center;
  min-width: max-content;
  font-size: 1.125rem;
  color: $color-dark;
  text-decoration: none;
  border-radius: $radius-button;
  padding: .5rem 1rem;
  transition: all .2s ease-in-out;
  border: solid 1px transparent;
  outline: none;

  &--tertiary {
    color: $color-primary;
    text-transform: uppercase;
    font-size: .8rem;

    &:focus, &:hover, &:active {
      border: solid 1px $color-primary;
    }

    svg {
      margin-left: .5em;
      height: 1em;
      color: $color-primary;
    }
  }
}