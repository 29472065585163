@import '../../theme.scss';

.sheet {
  position: relative;
  margin-top: -$radius-content;
  border-radius: $radius-content $radius-content 0 0;
  padding: 1rem 1rem;
  min-height: 100vh;
  background: $color-gray-lightest;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.15);
  z-index: 2;

  &__inner {
    max-width: $max-width-content;
    margin: auto;
    transition: all .5s ease-in-out;
    transition-delay: .2s;

    > section {
      > section {
        padding-bottom: 2rem;
      }

      p {
        margin-top: 0;
      }

      > img {
        width: calc(100% + 2rem);
        margin-left: -1rem;
        border-radius: $radius-sheet-image;
        margin-bottom: 1rem;
      }
    }

    .animated-page-enter & {
      opacity: 0; 
    }

    .animated-page-exit & {
      opacity: 0;
    }
  }
}