@import '../../theme.scss';

.sticky_header {
  z-index: 10;
  position: sticky;
  top: 0;
  padding: 0;
  margin: 0 -1rem;
  overflow: hidden;
  z-index: 10;

  &__inner {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 600px;
    background: $color-gray-lightest;

    a {
      position: absolute;
      right: 1rem;
    }

    * {
      position: relative;
      z-index: 1000;
    }
  }

  &__shadow {
    margin: 0 -1rem;
    position: sticky;
    top: 62px;
    z-index: 8;
    height: 5px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, .2), transparent);
    border-radius: 0 0 10px 10px;
  }

  &__cover {
    position: relative;
    height: 10px;
    margin: -5px -1rem 0;
    z-index: 9;
    background: $color-gray-lightest;
  }

  h2 {
    height: min-content;
    font-size: 1.35rem;
    padding-right: .5rem;
    padding-bottom: .1rem;
    border-bottom: solid 2px $color-primary;
  }
}