@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;900&display=swap);
.chevron--left {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.navigation {
  max-width: calc(600px + 2rem);
  border-radius: 15px 15px 0 0;
  margin: 0 auto;
  z-index: 100;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  max-height: 33.33vh;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.25);
  transition: all .2s ease-in;
  -webkit-transform: translateY(calc(100% - 57px));
          transform: translateY(calc(100% - 57px)); }
  .navigation--open {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  .navigation__backdrop {
    border-radius: 15px 15px 0 0;
    position: fixed;
    bottom: 0;
    left: calc(calc(calc(100vw - 100%) / 2) * -1);
    height: 0;
    width: 100vw;
    background: rgba(0, 0, 0, 0.2);
    z-index: -1;
    opacity: 0;
    transition: opacity .3s ease-in-out, height .5s ease-in-out; }
    .navigation--open .navigation__backdrop {
      opacity: 1;
      height: 150vh;
      width: 100vw;
      transition: opacity .3s ease-in-out; }
  .navigation__actions {
    z-index: 10;
    border-radius: 15px 15px 0 0;
    background: #fff;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style: none;
    padding: .5rem;
    margin: 0;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1); }
  .navigation__action {
    margin: 0;
    padding: 0 .5rem;
    list-style: none;
    flex: 1 1 auto; }
    .navigation__action:first-child {
      padding-left: 0; }
    .navigation__action:last-child {
      padding-right: 0; }
  .navigation__button {
    margin: 0;
    padding: .5rem 1rem;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border: none;
    background: transparent;
    width: 100%;
    outline: none;
    cursor: pointer;
    position: relative;
    border-radius: 15px;
    border: solid 2px transparent;
    transition: background .3s ease-in, color .1s ease-in-out, border-color .3s ease-in; }
    .navigation__button[disabled] {
      opacity: .3; }
    .navigation__button:focus {
      transition-delay: 0;
      border: solid 2px #8773BF;
      color: #8773BF; }
    .navigation__button[aria-pressed="true"] {
      background: #8773BF;
      color: #fff; }
      .navigation__button[aria-pressed="true"]:focus {
        background: #fff;
        color: #8773BF; }
    .navigation__button svg {
      vertical-align: middle;
      height: 1.5rem;
      width: auto;
      margin: 0;
      padding: 0; }
  .navigation__inner {
    background: #f4f5f7;
    padding: 1rem;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    grid-gap: 1rem; }
    .navigation__inner li {
      list-style: none;
      text-align: center;
      position: relative; }
      .navigation__inner li a {
        position: relative;
        display: block;
        padding: .5rem;
        text-decoration: none;
        color: #000;
        border: solid 2px transparent;
        border-radius: 15px;
        outline: none;
        transition: all .2s ease-in-out; }
        .navigation__inner li a::after {
          display: block;
          content: '';
          width: 10px;
          height: 2px;
          background: #8773BF;
          border-radius: 5px;
          position: absolute;
          bottom: .25rem;
          left: 50%;
          -webkit-transform: translateX(-50%);
                  transform: translateX(-50%);
          opacity: 0;
          transition: all .3s ease-in-out; }
        .navigation__inner li a:focus, .navigation__inner li a:active, .navigation__inner li a:hover {
          border-color: #8773BF; }
          .navigation__inner li a:focus::after, .navigation__inner li a:active::after, .navigation__inner li a:hover::after {
            opacity: 1; }
        .navigation__inner li a[aria-current] {
          background: #fff;
          color: #000; }
          .navigation__inner li a[aria-current]:focus, .navigation__inner li a[aria-current]:active, .navigation__inner li a[aria-current]:hover {
            background: #fff;
            color: #8773BF;
            border-color: #8773BF; }
            .navigation__inner li a[aria-current]:focus::after, .navigation__inner li a[aria-current]:active::after, .navigation__inner li a[aria-current]:hover::after {
              background: #8773BF; }
          .navigation__inner li a[aria-current]::after {
            background: rgba(0, 0, 0, 0.2);
            opacity: 1; }

.sheet {
  position: relative;
  margin-top: -15px;
  border-radius: 15px 15px 0 0;
  padding: 1rem 1rem;
  min-height: 100vh;
  background: #f4f5f7;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.15);
  z-index: 2; }
  .sheet__inner {
    max-width: 600px;
    margin: auto;
    transition: all .5s ease-in-out;
    transition-delay: .2s; }
    .sheet__inner > section > section {
      padding-bottom: 2rem; }
    .sheet__inner > section p {
      margin-top: 0; }
    .sheet__inner > section > img {
      width: calc(100% + 2rem);
      margin-left: -1rem;
      border-radius: 10px;
      margin-bottom: 1rem; }
    .animated-page-enter .sheet__inner {
      opacity: 0; }
    .animated-page-exit .sheet__inner {
      opacity: 0; }

.button {
  display: flex;
  align-items: center;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  font-size: 1.125rem;
  color: #000;
  text-decoration: none;
  border-radius: 10px;
  padding: .5rem 1rem;
  transition: all .2s ease-in-out;
  border: solid 1px transparent;
  outline: none; }
  .button--tertiary {
    color: #8773BF;
    text-transform: uppercase;
    font-size: .8rem; }
    .button--tertiary:focus, .button--tertiary:hover, .button--tertiary:active {
      border: solid 1px #8773BF; }
    .button--tertiary svg {
      margin-left: .5em;
      height: 1em;
      color: #8773BF; }

.sticky_header {
  z-index: 10;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 0;
  margin: 0 -1rem;
  overflow: hidden;
  z-index: 10; }
  .sticky_header__inner {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 600px;
    background: #f4f5f7; }
    .sticky_header__inner a {
      position: absolute;
      right: 1rem; }
    .sticky_header__inner * {
      position: relative;
      z-index: 1000; }
  .sticky_header__shadow {
    margin: 0 -1rem;
    position: -webkit-sticky;
    position: sticky;
    top: 62px;
    z-index: 8;
    height: 5px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), transparent);
    border-radius: 0 0 10px 10px; }
  .sticky_header__cover {
    position: relative;
    height: 10px;
    margin: -5px -1rem 0;
    z-index: 9;
    background: #f4f5f7; }
  .sticky_header h2 {
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    font-size: 1.35rem;
    padding-right: .5rem;
    padding-bottom: .1rem;
    border-bottom: solid 2px #8773BF; }

.overview p {
  margin: 0; }

.hero {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 4rem 1rem;
  background: #8773BF;
  z-index: 1;
  transition: all .7s ease-in-out;
  overflow: hidden;
  max-height: 300px; }
  .hero__inner {
    max-width: 600px;
    margin: auto;
    text-align: center;
    color: #fff;
    transition: all .3s ease-in-out;
    transition-delay: .5s;
    min-height: 100px; }
    .hero__inner * {
      text-shadow: 0 0 10px rgba(0, 0, 0, 0.5); }
    .hero__inner h2 {
      font-size: 1.1rem;
      font-weight: 400;
      letter-spacing: .1em;
      opacity: .8; }
    .animated-page-enter .hero__inner {
      opacity: 0; }
    .animated-page-exit .hero__inner {
      opacity: 0;
      transition-delay: 0 !important; }
  .hero__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: all .5s ease-in-out;
    transition-delay: .2s; }
    .hero__background img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 100%; }
  .animated-page-enter .hero {
    max-height: 0;
    padding: 0; }
  .animated-page-exit .hero {
    max-height: 0;
    padding: 0; }

.profile {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .profile__icon {
    display: block;
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 100%;
    margin-bottom: .5rem; }
    .profile__icon img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .profile__content {
    text-align: center;
    padding-left: 1rem;
    color: #fff; }
    .profile__content h1 {
      font-weight: 900; }
    .profile__content h2 {
      font-weight: 400; }
  .profile__social {
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    list-style: none;
    color: #fff; }
    .profile__social a {
      color: inherit;
      padding: .25rem 1rem; }
    .profile__social svg {
      width: 20px;
      height: auto; }

.card {
  background: #fff;
  border-radius: 10px;
  padding: 1.23rem 1.2rem 1.6rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  position: relative;
  overflow: hidden;
  transition: all .2s ease-in-out; }
  .card:focus-within {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 6px 10px rgba(0, 0, 0, 0.3);
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px); }
    .card:focus-within .card__background::after {
      top: -30%; }
  .card--background {
    background: transparent; }
  .card__background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1; }
    .card__background img {
      height: 100%;
      width: 100%;
      object-fit: cover; }
    .card__background::after {
      display: block;
      content: '';
      background: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.2));
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 130%;
      transition: all .2s ease-in-out; }
  .card h3 {
    color: #8773BF;
    margin: 0;
    font-size: 1.2rem; }
  .card h4 {
    margin-top: .25rem;
    font-size: 0.9rem;
    font-weight: 600;
    letter-spacing: .02rem;
    opacity: .7; }
  .card p {
    font-size: 1rem;
    margin-top: .75rem; }
  .card *:first-child {
    margin-top: 0; }
  .card *:last-child {
    margin-bottom: 0; }

.skills__content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(186px, 1fr));
  grid-gap: 1rem; }

.experience__content {
  display: grid;
  grid-gap: 1rem; }


.resources__featured {
  padding-bottom: 1rem; }
  .resources__featured .card {
    padding-top: 40%;
    color: #fff;
    position: relative; }
    .resources__featured .card__content {
      display: flex;
      height: calc(100% - 2rem);
      align-items: flex-end;
      position: absolute;
      top: 0;
      left: 0;
      padding: 1rem; }
    .resources__featured .card h2 {
      font-size: 1.35rem;
      max-width: 275px;
      text-shadow: 0 0 10px rgba(0, 0, 0, 0.5); }
    .resources__featured .card a {
      color: inherit;
      text-decoration: none;
      outline: none; }
      .resources__featured .card a::after {
        cursor: pointer;
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%; }

.resources__all {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 1rem;
  padding: 0; }
  .resources__all .card {
    position: relative;
    padding-top: 40%;
    color: #fff; }
    .resources__all .card__content {
      display: flex;
      padding: 1rem;
      height: calc(100% - 2rem);
      justify-content: flex-end;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0; }
    .resources__all .card h2 {
      font-size: 1.1rem;
      max-width: 275px;
      text-shadow: 0 0 10px rgba(0, 0, 0, 0.5); }
    .resources__all .card h3 {
      font-size: .9rem;
      font-weight: 400;
      letter-spacing: .1em;
      opacity: .8;
      color: #fff; }
    .resources__all .card a {
      color: inherit;
      text-decoration: none;
      outline: none; }
      .resources__all .card a::after {
        cursor: pointer;
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%; }

.fourOhFour .hero {
  text-align: center;
  color: #fff; }

.fourOhFour__content {
  max-width: 600px;
  margin: auto;
  padding: 2rem 1rem;
  text-align: center; }
  .fourOhFour__content h2 {
    text-align: center;
    width: -webkit-max-content !important;
    width: -moz-max-content !important;
    width: max-content !important;
    margin: auto;
    padding-left: .5rem; }
  .fourOhFour__content p {
    margin-top: 2rem; }
  .fourOhFour__content a {
    color: #8773BF;
    font-weight: 900;
    text-decoration: none; }

html, body {
  margin: 0;
  background: #f4f5f7; }

.app {
  position: relative;
  font-family: roboto;
  background: #f4f5f7; }
  .app h1 {
    font-size: 1.56rem;
    margin: .1em; }
  .app h2 {
    font-size: 1.19rem;
    margin: .1em; }
  .app p {
    line-height: 1.45em; }
  .app .page {
    min-height: 100vh; }
  .app .animated-page-enter {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10; }

