@import '../../theme.scss';

.hero {
  position: sticky;
  top: 0;
  padding: 4rem 1rem;
  background: $color-primary;
  z-index: 1;
  transition: all .7s ease-in-out;
  overflow: hidden;
  max-height: 300px;

  &__inner {
    max-width: 600px;
    margin: auto;
    text-align: center;
    color: $color-light;
    transition: all .3s ease-in-out;
    transition-delay: .5s;
    min-height: $min-height-hero;

    * {
      text-shadow: 0 0 10px rgba(0, 0, 0, .5);
    }

    h2 {
      font-size: 1.1rem;
      font-weight: 400;
      letter-spacing: .1em;
      opacity: .8;
    }

    .animated-page-enter & {
      opacity: 0;
    }
    
    .animated-page-exit & {
      opacity: 0;
      transition-delay: 0 !important;
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: all .5s ease-in-out;
    transition-delay: .2s;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 100%;
    }
  }

  .animated-page-enter & {
    max-height: 0;
    padding: 0;
  }

  .animated-page-exit & {
    max-height: 0;
    padding: 0;
  }
}