$color-primary: #8773BF;
$color-light: #fff;
$color-dark: #000;
$color-gray-lightest: #f4f5f7;

$radius-navigation: 15px;
$radius-content: 15px;
$radius-card: 10px;
$radius-button: 10px;
$radius-sheet-image: 10px;

$shadow-card: 0 4px 8px rgba(0, 0, 0, 0.25);
$shadow-card-focus: 0 4px 8px rgba(0, 0, 0, 0.25), 0 6px 10px rgba(0, 0, 0, .3);
$shadow-navigation: 0 -4px 8px rgba(0, 0, 0, .25);

$max-width-content: 600px;
$max-width-navigation: 300px;

$min-height-hero: 100px;

$border-size-navigation-button: 2px;