@import '../../theme.scss';

.navigation {
  max-width: calc(600px + 2rem);
  border-radius: $radius-navigation $radius-navigation 0 0;
  margin: 0 auto;
  z-index: 100;
  position: sticky;
  bottom: 0;
  max-height: 33.33vh;
  box-shadow: $shadow-navigation;
  transition: all .2s ease-in;
  transform: translateY(calc(100% - 57px));

  &--open {
    transform: translateY(0);
  }

  &__backdrop {
    border-radius: $radius-navigation $radius-navigation 0 0;
    position: fixed;
    bottom: 0;
    left: calc(calc(calc(100vw - 100%) / 2) * -1);
    height: 0;
    width: 100vw;
    background: rgba(0, 0, 0, .2);
    z-index: -1;
    opacity: 0;
    transition: opacity .3s ease-in-out, height .5s ease-in-out;

    .navigation--open & {
      opacity: 1;
      height: 150vh;
      width: 100vw;
      transition: opacity .3s ease-in-out;
    }
  }

  &__actions {
    z-index: 10;
    border-radius: $radius-navigation $radius-navigation 0 0;
    background: $color-light;
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style: none;
    padding: .5rem;
    margin: 0;
    border-bottom: solid 1px rgba(0, 0, 0, .1);
  }

  &__action {
    margin: 0;
    padding: 0 .5rem;
    list-style: none;
    flex: 1 1 auto;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  &__button {
    margin: 0;
    padding: .5rem 1rem;
    appearance: none;
    border: none;
    background: transparent;
    width: 100%;
    outline: none;
    cursor: pointer;
    position: relative;
    border-radius: $radius-navigation;
    border: solid $border-size-navigation-button transparent;
    transition: background .3s ease-in, color .1s ease-in-out, border-color .3s ease-in;

    &[disabled] {
      opacity: .3;
    }

    &:focus {
      transition-delay: 0;
      border: solid $border-size-navigation-button $color-primary;
      color: $color-primary;
    }

    &[aria-pressed="true"] {
      background: $color-primary;
      color: $color-light;

      &:focus {
        background: $color-light;
        color: $color-primary;
      }
    }

    svg {
      vertical-align: middle;
      height: 1.5rem;
      width: auto;
      margin: 0;
      padding: 0;
    }
  }

  &__inner {
    background: $color-gray-lightest;
    padding: 1rem;
    // max-width: $max-width-navigation;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    grid-gap: 1rem;

    li {
      list-style: none;
      text-align: center;
      position: relative;
      
      a {
        position: relative;
        display: block;
        padding: .5rem;
        text-decoration: none;
        color: $color-dark;
        border: solid 2px transparent;
        border-radius: $radius-navigation;
        outline: none;
        transition: all .2s ease-in-out;

        &::after {
          display: block;
          content: '';
          width: 10px;
          height: 2px;
          background: $color-primary;
          border-radius: 5px;
          position: absolute;
          bottom: .25rem;
          left: 50%;
          transform: translateX(-50%);
          opacity: 0;
          transition: all .3s ease-in-out;
        }

        &:focus, &:active, &:hover {
          border-color: $color-primary;

          &::after {
            opacity: 1;
          }
        }
        
        &[aria-current] {
          background: $color-light;
          color: $color-dark;

          &:focus, &:active, &:hover {
            background: $color-light;
            color: $color-primary;
            border-color: $color-primary;

            &::after {
              background: $color-primary;
            }
          }

          &::after {
            background: rgba(0, 0, 0, .2);
            opacity: 1;
          }
        }
      }
    }
  }
}