@import './theme.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;900&display=swap');

html, body {
  margin: 0;
  background: $color-gray-lightest;
}

.app {
  position: relative;
  font-family: roboto;
  background: $color-gray-lightest;

  h1 {
    font-size: 1.56rem;
    margin: .1em;
  }

  h2 {
    font-size: 1.19rem;
    margin: .1em;
  }

  p {
    line-height: 1.45em;
  }

  .page {
    min-height: 100vh;
  }

  .animated-page-enter {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }
}
