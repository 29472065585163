@import '../../theme.scss';

.card {
  background: $color-light;
  border-radius: $radius-card;
  padding: 1.23rem 1.2rem 1.6rem;
  box-shadow: $shadow-card;
  position: relative;
  overflow: hidden;
  transition: all .2s ease-in-out;

  &:focus-within {
    box-shadow: $shadow-card-focus;
    transform: translateY(-5px);

    .card__background {
      &::after {
        top: -30%;
      }
    }
  }

  &--background {
    background: transparent;
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    &::after {
      display: block;
      content: '';
      background: linear-gradient(to top, rgba(0, 0, 0, .6), rgba(0, 0, 0, .2));
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 130%;
      transition: all .2s ease-in-out;
    }
  }

  h3 {
    color: $color-primary;
    margin: 0;
    font-size: 1.2rem;
  }

  h4 {
    margin-top: .25rem;
    font-size: 0.9rem;
    font-weight: 600;
    letter-spacing: .02rem;
    opacity: .7;
  }

  p {
    font-size: 1rem;
    margin-top: .75rem;
  }

  * {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}