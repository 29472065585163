@import '../../theme.scss';

.resources {
  &__featured {
    padding-bottom: 1rem;
    
    .card {
      padding-top: 40%;
      color: $color-light;
      position: relative;


      &__content {
        display: flex;
        height: calc(100% - 2rem);
        align-items: flex-end;
        position: absolute;
        top: 0;
        left: 0;
        padding: 1rem;
      }

      h2 {
        font-size: 1.35rem;
        max-width: 275px;
        text-shadow: 0 0 10px rgba(0, 0, 0, .5);
      }

      a {
        color: inherit;
        text-decoration: none;
        outline: none;

        &::after{
          cursor: pointer;
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  &__all {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 1rem;
    padding: 0;

    .card {
      position: relative;
      padding-top: 40%;
      color: $color-light;

      &__content {
        display: flex;
        padding: 1rem;
        height: calc(100% - 2rem);
        justify-content: flex-end;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
      }

      h2 {
        font-size: 1.1rem;
        max-width: 275px;
        text-shadow: 0 0 10px rgba(0, 0, 0, .5);
      }

      h3 {
        font-size: .9rem;
        font-weight: 400;
        letter-spacing: .1em;
        opacity: .8;
        color: $color-light;
      }

      a {
        color: inherit;
        text-decoration: none;
        outline: none;

        &::after{
          cursor: pointer;
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}